<template>
    <slot />
</template>

<style lang="scss">
    @import "tippy.js/dist/tippy.css";
    @import "resources/sass/partials/variables";
    @import "resources/sass/design-system/variables";

    .tippy-box-base-styles {
        border-radius: 16px;
        background-color: $white;
        color: $pill-color;
        font-size: 12px;
        font-weight: 500;

        // needed because global styles overwrite tippy styles
        *:not(a) {
            color: $pill-color;
        }
    }

    .tippy-dark-box-base-styles {
        border: 1px solid $lavender;
        background-color: $tooltip-background;
        color: $white;

        * {
            color: $white;
        }

        .tippy-arrow {
            color: $tooltip-background;
        }

        .tippy-content {
            padding: 0;
        }
    }

    .tippy-box[data-theme~="base"] {
        @extend .tippy-box-base-styles;
    }

    .tippy-box[data-theme~="default"] {
        @extend .tippy-box-base-styles;
        padding: 8px;
        box-shadow: 0 1px 2px rgba($ship-cove, 0.12), 0 8px 32px rgba($ship-cove, 0.24);
    }

    .tippy-box[data-theme~="base-light"] {
        @extend .tippy-box-base-styles;

        *:not(a) {
            color: $black;
        }

        .tippy-arrow {
            color: $baseWhite;
        }
    }

    .tippy-box[data-theme~="base-dark"] {
        @extend .tippy-box-base-styles;
        @extend .tippy-dark-box-base-styles;
    }

    .dark-theme {
        .tippy-box[data-theme~="base"] {
            @extend .tippy-dark-box-base-styles;
        }

        .tippy-box[data-theme~="default"] {
            @extend .tippy-dark-box-base-styles;
            box-shadow: none;
        }
    }

    // only dark themed style, with no content paddings
    .tippy-box[data-theme~="base-dark"] {
        @extend .tippy-dark-box-base-styles;
        overflow: hidden;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 500;
    }

    // Transparent Tippy theme without background and arrow
    .tippy-box[data-theme~="transparent"] {
        border: none;
        background-color: transparent;
        box-shadow: none;

        .tippy-arrow {
            display: none;
        }
    }
</style>
